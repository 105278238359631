import React,{useEffect,useState} from 'react';
import axios from 'axios'

function MessageComp(props) {
    const[studentInf,setStudentInf]=useState({})
    let url='https://lecturer-update-server.herokuapp.com/student/getStudentInfo'
    useEffect(()=>{
        axios.get(url,{ params: { id: props.current.from}})
        .then(res=>{
            setStudentInf(res.data.student);
            console.log(res.data);
        })
    },[])
    function delMsg(){
        axios.delete('https://lecturer-update-server.herokuapp.com/msg',{params:{id:props.current._id}})
        .then(res=>{
            props.refresh();
            console.log(res.data)
        })
        .catch(err=>{console.log(err)});
    }
    return (
        <tr>
            <td>{`${studentInf.firstname} ${studentInf.lastname}`}</td>
            <td>{studentInf.id}</td>
            <td>{studentInf.email}</td>
            <td>{props.current.subject}</td>
            <td>{props.current.message}</td>
            <td><a id="del-href" onClick={delMsg}>delete</a></td>
        </tr>
    )
}

export default MessageComp
