import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './Message.css';
import MessageComp from './MessageComp'

function Message() {
    // const [lecturerid, setLecturerid] = useState("");
    const [messages, setMessages] = useState([]);
    let url=`https://lecturer-update-server.herokuapp.com/msg/getAllMsg`

    let liName = ["li1", "li2", "li3", "li4", "li5", "li6"];//for change background color og current component in navbar
    function changeNavbarBColor() {
        liName.map((curr) => {
            if (curr === "li6") {
                dqs(curr).style.backgroundColor = "#9ad3bc";
            } else {
                dqs(curr).style.backgroundColor = "#327A81";
            }
        });
    }
    useEffect(() => {//handle the token
        changeNavbarBColor();
        let token = (!Cookies.get('token')) ? { message: "" } : JSON.parse(Cookies.get('token'));
        if (token.message === "auth succesfull") {
            checkAccessToken();
        } else {
            deleteCookieAndGoLogin();
        }
    }, []);

    function checkAccessToken() {
        let token = (JSON.parse(Cookies.get('token')));
        axios.post('https://lecturer-update-server.herokuapp.com/lecturer/checkAccessToken', { accessToken: token.accessToken })
            .then(res => {
                if (res.data.message === "auth succesfull") {//check if the access token that we had is not expire
                    getAllMsg(res.data.id);
                    return;
                } else {
                    if (res.data.message === "jwt expired") {//-notice : the acceess token expired,but doesn't attacked
                        axios.post('https://lecturer-update-server.herokuapp.com/lecturer/token', { refreshToken: token.refreshToken })
                            .then(res => {
                                if (res.data.accessToken !== "") {//if success generate a new access token
                                    token.accessToken = res.data.accessToken//update the old access token to new one
                                    Cookies.set('token', JSON.stringify(token));
                                    getAllMsg(res.data.id);
                                } else {
                                    deleteCookieAndGoLogin();
                                }
                            })
                            .catch(err => { console.log("error with fetch") })
                    } else {//res.data.message will be malformed , been attacked
                        deleteCookieAndGoLogin();
                    }
                }
            })
            .catch(err => { console.log("error with fetch") });
    }
    function deleteCookieAndGoLogin() {
        Cookies.remove('token');
        Cookies.remove('rememberMe');
        window.location = '/';
    }
    function getAllMsg(idlecturer){
        axios.post(url,{id:idlecturer})
        .then(studentMsg => {
            setMessages(studentMsg.data.messages);
            console.log(studentMsg.data.messages)
        })
        .catch((err) => `error With get studens ${err}`);
    }
    let studentMessages = messages.map((curr) => {
        return <MessageComp current={curr} refresh={()=>getAllMsg(curr.to)}/>
    });
    function dqs(ele) {
        return document.querySelector(`#${ele}`)
    }
    return (
        <div className="main-msg" id="main-msg">
            <div className="msg-content">
                <div className="title">
                    <h2>Students message</h2>
                </div>
                <table cellspacing="0">
                    <tr><th>Full Name</th><th>Student id</th><th>Email</th><th>Subject</th><th>Message</th><th>Delete</th></tr>   
                    {studentMessages}
                </table>
            </div>
        </div>
    )
}

export default Message
